import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
// Utilities
import kebabCase from "lodash/kebabCase"
// Components
import { graphql } from "gatsby"
import SEO from "../components/seo"
import Layout from "../components/layout"

const TagList = styled.li`
  display: inline-block;
  margin: 0;
  font-size: 17px;
  background-color: #ff457d;
  margin-right: 10px;
  margin-top: 10px;
  color: white;
  font-family: Montserrat;
  border-style: solid;
  border-color: white;
  box-shadow: 5px 10px 18px #888888;
  min-width: auto;
  padding-left: 9px;
  padding-right: 9px;
  text-align: center;
`

const TagsPage = ({  
  data: {
    allMarkdownRemark: { group },
    site: {
      siteMetadata: { title },
    },
  },
}) => (
  <Layout title={`${title}: Topics`} location={title}>
      <div>
        On the tags page you can find an over view of all topics I wrote about. Click on 
        a topic to see all articles related to that topic.
      </div>
      <br></br>
      <div>
   
        {group.map(tag => (
          <TagList>
            <a 
              href={`/tags/${kebabCase(tag.fieldValue)}/`}
              style={{
              boxShadow: `none`,
              textDecoration: `none`,
              color: `white`,
            }}
            >
              {tag.fieldValue} ({tag.totalCount})
            </a>
            <SEO title={tag.fieldValue} description={tag.fieldValue} />
          </TagList>
          
        ))}
      </div>
  </Layout>
)
TagsPage.propTypes = {
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      group: PropTypes.arrayOf(
        PropTypes.shape({
          fieldValue: PropTypes.string.isRequired,
          totalCount: PropTypes.number.isRequired,
        }).isRequired
      ),
    }),
    site: PropTypes.shape({
      siteMetadata: PropTypes.shape({
        title: PropTypes.string.isRequired,
      }),
    }),
  }),
}
export default TagsPage
export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(
      limit: 2000
      filter: { frontmatter: { published: { eq: true } } }
      ) {
      group(field: frontmatter___tags)
       {
        fieldValue
        totalCount
      }
    }

  }
`